@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@400;700&display=swap');

.main-title {
  font-family: 'Fredoka', sans-serif;
  font-size: clamp(1.8rem, 8vw, 3rem); /* Smaller responsive font size */
  color: #000000;
  margin: 0 0 5px;
  display: block; /* Full width */
  width: 100%; /* Fill container */
  text-align: center;
  letter-spacing: 0.05em; /* Reduced letter spacing */
  line-height: 1.1;
  font-weight: 700; /* Bold weight */
  white-space: nowrap; /* Force single line */
  overflow: hidden; /* Prevent overflow */
  text-overflow: ellipsis; /* Show ellipsis if text overflows */
}

.subtitle {
  font-family: 'Fredoka', sans-serif;
  color: #666;
  margin: 0;
  padding: 0;
  line-height: 1.3;
  /* Smaller font size to ensure entire text fits */
  font-size: clamp(0.55rem, 2.5vw, 0.9rem);
  display: block;
  width: 100%;
  text-align: center;
  letter-spacing: 0.01em; /* Further reduced letter spacing */
  white-space: nowrap; /* Force single line */
  overflow: hidden; /* Prevent overflow */
  text-overflow: ellipsis; /* Show ellipsis if text overflows */
}

/* Container to align both elements */
.title-container {
  text-align: center;
  width: 448px;
  margin: 15px auto 10px; /* Reduced from 20px auto 20px to match other spacing */
  overflow: hidden; /* Prevent overflow */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Remove unused custom properties */
:root {
  /* For potential future use */
}

.game-container {
    text-align: center;
    font-family: Arial, sans-serif;
    position: relative;  /* Add this to contain absolute positioned elements */
  }
  
  .grid {
    display: grid;
    grid-template-columns: repeat(5, 80px);
    grid-template-rows: repeat(5, 80px);
    gap: 8px;
    width: 432px; /* Fixed width: (80px * 5) + (8px * 4) = 432px */
    height: 432px; /* Fixed height: (80px * 5) + (8px * 4) = 432px */
    justify-content: center;
    margin: 20px auto; /* Center the grid */
    background: #f8f9fa;
    padding: 8px;
    border-radius: 10px;
    position: relative;
    touch-action: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    will-change: contents;
    box-sizing: content-box; /* Ensure padding is added to the width */
    /* Ensure the grid doesn't interfere with dragging */
    pointer-events: auto;
    z-index: 1;
  }
  
  
  .tile {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-weight: bold;
    border-radius: 8px;
    background-color: #ffffff;
    border: none;
    cursor: grab;
    user-select: none;
    touch-action: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    box-shadow: 
      inset 0px 0px 2px rgba(255, 255, 255, 0.8),
      inset 0px 0px 5px rgba(255, 255, 255, 0.5),
      -2px -2px 5px rgba(255, 255, 255, 0.5),
      2px 2px 5px rgba(0, 0, 0, 0.3),
      4px 4px 5px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .tile:active {
    cursor: grabbing;
  }
  
  .tile:hover {
    transform: scale(1.05);
    transition: transform 0.1s;
  }
  
  .tile.empty {
    background: none;
    border: none;
    cursor: default;
  }
  
  button {
    margin-top: 10px; /* Reduced from 20px to match the spacing between board and drag kvar */
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #218838;
  }

  .tile.correct {
    background-color: #28a745;
    color: white;
    cursor: default; /* Default cursor for correct tiles */
    box-shadow: 
      inset 0px 0px 2px rgba(255, 255, 255, 0.8),
      inset 0px 0px 5px rgba(255, 255, 255, 0.5),
      -2px -2px 5px rgba(255, 255, 255, 0.3),
      2px 2px 5px rgba(0, 0, 0, 0.3),
      4px 4px 5px rgba(0, 0, 0, 0.1);
  }
  .tile.incorrect {
    background-color: #ccc;
    box-shadow: 
      inset 0px 0px 2px rgba(255, 255, 255, 0.8),
      inset 0px 0px 5px rgba(255, 255, 255, 0.5),
      -2px -2px 5px rgba(255, 255, 255, 0.3),
      2px 2px 5px rgba(0, 0, 0, 0.2),
      4px 4px 5px rgba(0, 0, 0, 0.1);
  }
  
  .tile.hidden {
    visibility: hidden;
    width: 0;
    height: 0;
    border: none;
    padding: 0;
    margin: 0;
  }

  .tile.misplaced {
    background-color: #ffc107; /* Yellow */
    color: black;
    box-shadow: 
      inset 0px 0px 2px rgba(255, 255, 255, 0.8),
      inset 0px 0px 5px rgba(255, 255, 255, 0.5),
      -2px -2px 5px rgba(255, 255, 255, 0.3),
      2px 2px 5px rgba(0, 0, 0, 0.3),
      4px 4px 5px rgba(0, 0, 0, 0.1);
  }

  /* Winning Animation - Bouncing Tiles & Fireworks */
.win {
  color: black;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  position: relative;  /* Change from fixed to relative */
  width: 100%;
  margin-bottom: 20px; /* Add bottom margin for spacing */
  z-index: 10; /* Higher than word list */
}

.fireworks {
  animation: fireworks 1.5s infinite alternate;
}

/* Losing Animation - Dark Grey Tiles */
.lose {
  color: black;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  position: relative;  /* Change from fixed to relative */
  width: 100%;
  margin-bottom: 20px; /* Add bottom margin for spacing */
}

.lose-grid:not(.win-grid) .tile {
  background-color: #000000 !important;
  color: #ffffff !important;
}

/* Individual lose tile override */
.lose-tile {
  background-color: #000000 !important;
  color: #ffffff !important;
}

/* Special override for correct tiles when in lose state */
.lose-grid:not(.win-grid) .tile.correct,
.lose-tile.correct {
  background-color: #424242 !important;
  color: #ffffff !important;
}

/* Winning Tiles - Keep original colors */
.win-grid .tile {
  animation: bounce 1s infinite; /* Only keep the bouncing effect */
}

.win-tile {
  animation: bounce 1s infinite; /* Only keep the bouncing effect */
}

/* Bouncing Effect for Winning */
@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

/* Fireworks Effect */
@keyframes fireworks {
  0% { opacity: 0.5; transform: scale(0.8); }
  100% { opacity: 1; transform: scale(1.2); }
}

/* Shaking Effect for Losing Message */
@keyframes shake {
  0% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  100% { transform: translateX(-5px); }
}

.fireworks-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999;
}

.firework {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: radial-gradient(circle, 
    rgba(255,223,0,1) 0%, 
    rgba(255,200,0,1) 25%, 
    rgba(255,174,0,1) 50%, 
    rgba(255,145,0,1) 75%, 
    rgba(255,0,0,1) 100%
  );
  box-shadow: 0 0 10px #ffdb00,
              0 0 20px #ffdb00,
              0 0 30px #ffdb00,
              0 0 40px #ff9100,
              0 0 70px #ff1500;
}

@keyframes explode {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.drag-kvar {
  font-family: 'Fredoka', sans-serif; /* Use the Fredoka font */
  font-size: 24px; /* Adjust size as needed */
  letter-spacing: 1px; /* Optional: Add some spacing for a smoother look */
  transition: all 0.3s ease; /* Smooth transition for any hover effects */
  margin: 15px 0 10px 0; /* Keep top margin the same */
}

.moves-count {
  color: #000000; /* Black color for the number */
}

.drag-text {
  color: #424242; /* Grey color for "DRAG KVAR" text */
}

.game-instructions {
  font-family: 'Fredoka', sans-serif;
  text-align: center;
  margin-bottom: 15px;
  font-size: 14px;
  color: #424242;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.game-instructions p {
  margin: 3px 0;
}

.game-goal {
  margin-top: 8px !important;
  font-weight: 500;
  font-size: 15px;
}

.today-date {
  font-family: 'Fredoka', sans-serif;
  font-size: 1.2rem;
  color: #777;
  text-align: center;
  margin-bottom: 10px; /* Keep this the same */
}

.title {
  font-family: 'Fredoka', sans-serif; /* Use the Fredoka font */
  font-weight: bold;
  font-size: 36px; /* Adjust size as needed */
  margin: 20px 0; /* Add some margin for spacing */
}

.game-over-message {
  font-family: 'Fredoka', sans-serif; /* Use the Fredoka font */
  font-size: 24px; /* Adjust size as needed */
  color: black; /* Ensure the text color is set */
  font-weight: normal; /* Set to normal to remove bold */
}

.game-completion-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.game-over-message div:first-child {
  margin-bottom: 15px; /* Space between message and stars */
}

.star-rating {
  font-size: 28px;
  line-height: 1;  /* Add this to match the time height */
  display: flex;   /* Add this */
  align-items: center;  /* Add this */
}

/* Countdown and Share Button Styles */
.next-game-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 300px;
  margin: 20px auto;
  padding: 15px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.countdown-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.countdown-title {
  font-size: 14px;
  font-weight: bold;
  margin: 0 0 5px 0;
  color: #555;
}

.countdown-timer {
  font-size: 22px;
  font-weight: bold;
  margin: 0;
  color: #333;
  font-family: 'Fredoka', sans-serif;
}

.share-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.share-button:hover {
  background-color: #45a049;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .next-game-container {
    max-width: 250px;
  }
  
  .countdown-timer {
    font-size: 18px;
  }
  
  .share-button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .next-game-container {
    flex-direction: column;
    max-width: 200px;
  }
  
  .countdown-section {
    margin-bottom: 10px;
    align-items: center;
    width: 100%;
  }
  
  .share-button {
    width: 100%;
  }
}

/* Word List Styles */
.word-list-section {
  margin-top: 50px; /* Increase top margin for better separation */
  animation: fadeIn 1s ease-in;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 60px;
  position: relative; /* Ensure proper stacking context */
  z-index: 5; /* Lower than game message but still above other elements */
}

.word-list-container {
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
}

.word-list-title {
  font-family: 'Fredoka', sans-serif;
  color: #e1ad08;
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8rem;
  border-bottom: 2px solid #f0f0f0;
  padding-bottom: 10px;
}

.word-item {
  margin-bottom: 25px;
  padding-bottom: 20px;
  border-bottom: 1px dashed #e0e0e0;
}

.word-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
}

.word {
  font-family: 'Fredoka', sans-serif;
  font-size: 1.4rem;
  font-weight: bold;
  color: #2c3e50;
  margin-bottom: 5px;
}

.pronunciation {
  font-family: monospace;
  color: #666;
  font-size: 1rem;
  font-weight: normal;
  margin-left: 10px;
}

.definition {
  font-size: 1rem;
  margin-bottom: 8px;
  color: #444;
  line-height: 1.4;
}

.example {
  font-size: 0.95rem;
  color: #666;
  margin-bottom: 8px;
  font-style: italic;
  padding-left: 8px;
  border-left: 3px solid #e1ad08;
}

.etymology {
  font-size: 0.85rem;
  color: #777;
  line-height: 1.5;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Loading state styling */
.loading {
  font-family: 'Fredoka', sans-serif;
  font-size: 1.5rem;
  color: #e1ad08;
  text-align: center;
  margin: 50px auto;
  animation: pulse 1.5s infinite ease-in-out;
}

/* Error message styling */
.error-message {
  font-family: 'Fredoka', sans-serif;
  font-size: 1.5rem;
  color: #d9534f;
  text-align: center;
  margin: 50px auto;
  padding: 20px;
  background-color: rgba(217, 83, 79, 0.1);
  border-radius: 8px;
  max-width: 500px;
}

@keyframes pulse {
  0% { opacity: 0.6; }
  50% { opacity: 1; }
  100% { opacity: 0.6; }
}

/* Mobile drag improvements - removed duplicate styles */

/* Ensure the grid permits proper dragging */
.grid {
  position: relative;
  touch-action: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

/* Animation for drag */
@keyframes swap-animation {
  0% { transform: scale(1); }
  100% { transform: scale(1); }
}

/* Remove forced transitions on grid elements */
.grid div {
  /* Clear any potentially conflicting rules */
}

/* Simplified mobile experience */
@media (max-width: 768px) {
  .title-container {
    width: 100%;
    max-width: 448px;
    padding: 0;
    margin: 15px auto;
    box-sizing: border-box;
  }
  
  .main-title, .subtitle {
    padding: 0;
    width: calc(100vw - 30px);
    max-width: 432px;
  }
  
  .grid {
    /* Make grid fully responsive on mobile */
    width: calc(100vw - 30px);
    height: calc(100vw - 30px);
    max-width: 432px;
    max-height: 432px;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    gap: calc(1vw + 2px);
    padding: calc(0.5vw + 2px);
    margin: 10px auto;
    box-sizing: border-box;
    transform: none;
  }
  
  .tile {
    width: 100%;
    height: 100%;
    font-size: calc(5vw);
    box-sizing: border-box;
  }
  
  /* Match header and paragraph width to the grid */
  h1, h1 + p {
    width: calc(100vw - 30px);
    max-width: 432px;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    box-sizing: border-box;
  }
  
  h1 {
    font-size: clamp(1.6rem, 6vw, 2.8rem);
  }
  
  h1 + p {
    font-size: clamp(0.52rem, 2.2vw, 0.85rem);
  }
}

/* For very small screens and tablets in portrait */
@media (max-width: 480px) {
  .grid {
    width: calc(100vw - 20px);
    height: calc(100vw - 20px);
    gap: calc(0.8vw + 2px);
    padding: calc(0.4vw + 2px);
  }
  
  .tile {
    font-size: calc(5.5vw);
  }
  
  .main-title, h1 {
    font-size: clamp(1.4rem, 6vw, 2.5rem);
    width: calc(100vw - 20px);
  }
  
  .subtitle, h1 + p {
    font-size: clamp(0.45rem, 2vw, 0.8rem);
    width: calc(100vw - 20px);
  }
}

/* For very narrow screens like older iPhones */
@media (max-width: 320px) {
  .grid {
    width: calc(100vw - 10px);
    height: calc(100vw - 10px);
    gap: 4px;
    padding: 4px;
  }
  
  .tile {
    border-radius: 6px;
    font-size: calc(6vw);
  }
}

/* Set header and subheader to match grid width */
h1, h1 + p {
  width: 448px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  text-align: center;
}

/* Add styles to make text fit container width */
h1 {
  font-size: clamp(1.8rem, 8vw, 3rem);
  width: 100%;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1.1;
  white-space: nowrap; /* Force single line */
  overflow: hidden; /* Prevent overflow */
  text-overflow: ellipsis; /* Show ellipsis if text overflows */
}

h1 + p {
  font-size: clamp(0.55rem, 2.5vw, 0.9rem);
  width: 100%;
  line-height: 1.3;
  font-weight: 400;
  letter-spacing: 0.01em;
  white-space: nowrap; /* Force single line */
  overflow: hidden; /* Prevent overflow */
  text-overflow: ellipsis; /* Show ellipsis if text overflows */
}

/* End of file */

@keyframes glow {
  from {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 15px gold, 0 0 20px gold;
  }
  to {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px gold, 0 0 40px gold;
  }
}

/* Reset Counter Styles */
.reset-counter {
  font-family: 'Fredoka', sans-serif;
  font-size: 16px;
  text-align: center;
  margin: 10px 0 15px 0;
  letter-spacing: 2px;
}

/* Header Styles */
.header-container {
  width: 448px;
  margin: 15px auto 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title-link {
  text-decoration: none;
  color: inherit;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1;
}

.main-title {
  font-family: 'Fredoka', sans-serif;
  font-size: clamp(1.8rem, 8vw, 3rem);
  color: #000000;
  margin: 0;
  text-align: center;
  letter-spacing: 0.05em;
  line-height: 1.1;
  font-weight: 700;
}

.archive-link {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  z-index: 2;
  color: #000000;
}

.archive-link svg {
  width: 40px;
  height: 40px;
}

/* Add responsive styles */
@media (max-width: 480px) {
  .header-container {
    width: calc(100% - 30px);
    max-width: 448px;
    padding: 0 15px;
    margin: 15px auto 10px;
    box-sizing: border-box;
  }

  .archive-link {
    right: 15px;
  }
}

/* Game Date and Archive Title Styles */
.title-container {
  text-align: center;
  margin: 0 auto 20px;
  width: 448px;
}

.game-instructions {
  font-family: 'Fredoka', sans-serif;
  font-size: 1.0rem;
  color: #000000;
  margin: 0.5rem 0;
}

/* Archive Page Styles */
.archive-list {
  width: 448px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.archive-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  background-color: #fff;
  border-radius: 12px;
  text-decoration: none;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.archive-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  background-color: #f8f8f8;
}

.archive-date {
  font-family: 'Fredoka', sans-serif;
  font-size: 1.1rem;
  font-weight: 500;
  color: #444;
}

.archive-result {
  font-family: 'Fredoka', sans-serif;
  font-size: 1.1rem;
  color: #666;
  letter-spacing: 2px;
}

/* Responsive Design */
@media (max-width: 600px) {
  .archive-list {
    width: calc(100% - 30px);
    margin: 20px auto;
  }

  .archive-item {
    padding: 12px 16px;
  }

  .archive-date, .archive-result {
    font-size: 1rem;
  }
}

.archive-prompt {
  margin-top: 30px;
  text-align: center;
}

.archive-prompt p {
  font-family: 'Fredoka', sans-serif;
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 15px;
}

.archive-button {
  display: inline-block;
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  font-family: 'Fredoka', sans-serif;
  font-size: 1.2rem;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.archive-button:hover {
  background-color: #45a049;
}

.result-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 4px;
}

.attempts, .stars {
  font-family: 'Fredoka', sans-serif;
  font-size: 1.1rem;
  color: #666;
  letter-spacing: 2px;
  line-height: 1.2;
}

.archive-date-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.game-name {
  font-family: 'Fredoka', sans-serif;
  font-size: 1rem;
  color: #666;
}

.archive-date {
  font-family: 'Fredoka', sans-serif;
  font-size: 1rem;
  color: #666;
}

/* Update existing archive-item style to accommodate new layout */
.archive-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  background-color: #fff;
  border-radius: 12px;
  text-decoration: none;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

/* Update responsive styles */
@media (max-width: 600px) {
  .game-name {
    font-size: 1.1rem;
  }

  .archive-date {
    font-size: 0.9rem;
  }
}

.completion-info {
  display: flex;
  align-items: center;  /* This ensures vertical alignment */
  justify-content: center;
  gap: 20px;
  margin: 10px 0;
}

.completion-time {
  font-family: 'Fredoka', sans-serif;
  font-size: 28px;
  color: #666;
  line-height: 1;  /* Add this to match the star height */
  display: flex;   /* Add this */
  align-items: center;  /* Add this */
}
